export const environment = {
  production: true,
  encripcion: false,
  name: 'Produccion',
  wsUrl: 'https://cronosv.segurosatlas.com.mx/ServicesAtlasConmigo/',
  wsUrlHyperion: 'https://hyperion.segurosatlas.com.mx/WebAppPortales/api/cuentas/',
  firebaseConfig: {
    apiKey: "AIzaSyA-7tcoTBN7JlyIJrxbAqTUwkoyhm0p4ic",
    authDomain: "atlas-conmigo-web.firebaseapp.com",
    databaseURL: "https://atlas-conmigo-web.firebaseio.com",
    projectId: "atlas-conmigo-web",
    storageBucket: "atlas-conmigo-web.appspot.com",
    messagingSenderId: "119007903588",
    appId: "1:119007903588:web:273775c5703272d11f0471",
    measurementId: "G-72F1TV1CLG",
    vapidKey : ""
  },
  qrEndpoint: 'https://hyperion.segurosatlas.com.mx/Portales/Pages/QR/IdentificacionDigital.html?uid_inciso=',
  analyticsUrl : `https://www.googletagmanager.com/gtag/js?id=`,
  recaptcha: {
    siteKey: '6Ld7RyceAAAAABCMB_BXoAI8XpikQFjQ6dCDgOfS',
  },
  elijeTallerEndpoint:'https://direccionamiento-atlas.web.app/direccionamiento/inicio/',
  multipagosBBVAUrl : 'https://www.adquiramexico.com.mx/clb/endpoint/satlasPortalWeb',
  urlPagoLineaClasico : 'https://hyperion.segurosatlas.com.mx/Portales/Pages/General/PagoLinea2.aspx?',
  encryptedPassMP: "65WQXLSm9gEJ%aL",
  credencialeslLineaCaptura:{
    usuario: "PROCIN07",
    password: "4tL4$demo"
  }
};
