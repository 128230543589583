import { Component, OnInit, Input, Output, EventEmitter, OnChanges, ViewChild, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CentroPagosService } from 'src/app/services/centro-pagos/centro-pagos.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/@store/app.reducers';
import { DesactivarLoadingAction, MostrarMensajeErrorAction } from 'src/app/@store/actions';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MaskCardNumberPipe } from 'src/app/@shared/pipes';
import { retry, skipWhile, take, delay } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ActivarLoadingAction } from '../../../@store/actions/ui.actions';
import { isArray } from 'lodash';

export interface DatosPagoComponente {
  appOrigen: string;
  idCarrito: number;
  asegurado: boolean;
}

@Component({
  selector: 'pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.scss']
})
export class PagosComponent implements OnInit, OnChanges, OnDestroy {
  @BlockUI('loaderBlock') blockUIElement: NgBlockUI;
  @Input() datosPago: DatosPagoComponente;
  @Output() formPagoValido = new EventEmitter<boolean>(false);
  @Output() resultadoPago = new EventEmitter<any>();
  subsCambiosForm = new Subscription();
  subsTarjetaValor = new Subscription();
  mensajeLoader = 'Cargando...';
  meses = [];
  anios = [];
  fecha = moment();
  formPago: FormGroup;
  domiciliacion = false;
  domiOpcional = false;
  bancosMeses = [];
  descuento = [];
  existeDescuento = false;
  selectDescuento = false;
  tarjeta = '';
  importe = 0;
  cve_recibo = 0;
  articulos = [];
  canales = [];
  atributosDinamicos = ["NumeroTarjeta", "NumeroTarjetaFront", "NumeroTarjeta", "MesVencimiento", "AnioVencimiento", "NombreTarjetahabiente"];
  cargaCanalPago = false;
  opcionesPago = [
    {descripcion: 'PAGO EN UNA EXHIBICIÓN', valor: -1}
  ];
  avisoPrivacidad = new FormControl(null, Validators.requiredTrue);
  subsAvisoPrivacidad = new Subscription();

  constructor(private centroPagosService: CentroPagosService,
              private store: Store<AppState>,
              private cardMaks: MaskCardNumberPipe) { }

  ngOnInit() {
    // this.blockUIElement.start();
    this.crearFormTarjeta();
    this.cargarMesesAnios();

    this.store.select('usuario').pipe(skipWhile(wait => !wait.loaded),take(1), delay(500)).subscribe(usr => {
      if(this.formPago) this.formPago.get('Email').setValue(usr.email);
    });

    this.subsAvisoPrivacidad = this.avisoPrivacidad.statusChanges.subscribe(valido => {     
      this.formPagoValido.emit(valido == 'VALID' && this.formPago.valid ? true : false)
    });
    this.subsCambiosForm = this.formPago.statusChanges.subscribe(valido => {
      this.formPagoValido.emit(valido == 'VALID' && this.avisoPrivacidad.valid ? true : false)
      // if (valido == 'VALID' && this.avisoPrivacidad.valid){
      //   this.formPagoValido.emit(true);
      // }else{
      //   this.formPagoValido.emit(false);
      // }
    });
  }
  maskCard($event){
    const value = this.formPago.get('NumeroTarjetaFront').value;
    let transformar = true;

    if(value == ''){
        this.tarjeta = '';
        transformar = false;
    }else if($event.key == 'Backspace'){
      this.tarjeta = this.tarjeta.substring(0, this.tarjeta.length - 1);
      transformar = false;
    }else if(isNaN($event.key) || this.tarjeta.length >= 16){
      transformar = false;
    }

    if(transformar){
      this.tarjeta += $event.key;
      this.formPago.get('NumeroTarjetaFront').setValue(this.cardMaks.transform(this.tarjeta));
    }

    this.formPago.get('NumeroTarjeta').setValue(this.tarjeta);
  }

  ngOnChanges(){
    // debugger;    

    if(this.datosPago){
      this.mensajeLoader = 'Cargando carrito...';

      this.store.dispatch(ActivarLoadingAction());
      this.centroPagosService.consultarCarrito(this.datosPago.idCarrito).subscribe(data => {
        this.store.dispatch(DesactivarLoadingAction());
        
        this.articulos = data['Articulos'].filter(articulo => articulo.Estatus == 1);

        if(this.articulos.length == 0){
          this.store.dispatch(MostrarMensajeErrorAction({titulo :  'Error Carrito', mensaje : 'El carrito no cuenta con articulos'}));
          return;
        }

        this.importe = data['Importe'];

        let erroresCondiciones = "";

        this.articulos.forEach(articulo => {
          const condiciones = articulo["CondicionesCobro"];
          const regla0 = condiciones.find(condicion => condicion.Regla == 0);
          this.cve_recibo = articulo["CadenaIdentificacion"];

          if(regla0){
            erroresCondiciones += `\n Recibo ${articulo["CadenaIdentificacion"]} no puede ser pagado: \n ${regla0["MsjError"]}`;
          }
        });

        if(erroresCondiciones.length > 1){
          this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Pago no disponible', mensaje : erroresCondiciones}));
          return;
        }
        
        if(this.articulos.length == 1){

          const reglas = this.articulos[0]["CondicionesCobro"];

          const reglaDomiciliacion = reglas.find(condicion => condicion.Regla == 1);
          this.domiciliacion = reglaDomiciliacion.Aplica ? true : false;
          
          if (this.domiciliacion) {
            const preJson = this.validarJSONstring(reglaDomiciliacion.ResultadoJson);
            const json = Array.isArray(preJson)  ? preJson[0] : preJson;
            this.formPago.get('Domiciliacion').setValue(true);
            this.domiOpcional = json.domi_opcional || json.domi_opcional === 0 ? json.domi_opcional !== 1 : false; // domi_opcional == 1 Obligatorio
          }

          const aplicaMeses = reglas.find(condicion => condicion.Regla == 2).Aplica ? true : false;
          const aplicaDescuento = reglas.find(condicion => condicion.Regla == 6).Aplica ? true : false;
  
          if (aplicaMeses){
            this.bancosMeses = reglas.find(condicion => condicion.Regla == 2).Participantes;
            this.bancosMeses.forEach(banco => banco.seleccionado = false);
          }
  
          if (aplicaDescuento) {
            this.existeDescuento = true;
            this.descuento = JSON.parse(reglas.find(condicion => condicion.Regla == 6).ResultadoJson).Descuentos;
            this.opcionesPago.push({descripcion: `DESCUENTO POR 80 ANIVERSARIO.`, valor: this.descuento[0].cve_descuento});
            this.store.dispatch(ActivarLoadingAction());
            this.centroPagosService.actualizaDescuento(this.datosPago.idCarrito, this.cve_recibo, this.descuento, 1).subscribe((data: number) => {
              this.store.dispatch(DesactivarLoadingAction());
              let newImporte = data;
              if (newImporte != null) {
                this.importe = newImporte;
              }
              this.selectDescuento = true;
            }, error => {
              this.store.dispatch(DesactivarLoadingAction());
              if(error.status == 400){
                // this.blockUIElement.stop();
                this.store.dispatch(MostrarMensajeErrorAction({titulo :  'Pago no disponible', mensaje : error.error[0].Exception}));
                this.formPago.disable();
              }else{
                this.store.dispatch(MostrarMensajeErrorAction({titulo :  'Error en el sistema', mensaje : error.error}));
              }
            });

          }
        }

        if(this.datosPago.asegurado){
          this.store.dispatch(ActivarLoadingAction());
          
          this.centroPagosService.consultarCanalesPago().subscribe((data: any) => {
            this.store.dispatch(DesactivarLoadingAction());
            this.canales = data.Exito ? data.Respuesta : [];
            this.canales.push({
              IdCanalPago: 0,
              NombreTarjetahabiente: "",
              Tipo: "",
              Banco: "OTRA TARJETA",
              NumeroCorto: "",
              Numero: "",
              Vencimiento: ""
            });

            if(this.canales.length > 1){
              const ultimaTarjeta = this.canales[this.canales.length - 2];
              this.formPago.get('IdCanalPago').setValue(ultimaTarjeta["IdCanalPago"]);
              this.cargaCanalPago = true;
              this.atributosDinamicos.forEach(atributo => {
                this.formPago.controls[atributo].disable();
              });
            }else{
              this.formPago.get('IdCanalPago').setValue(0);
              this.cargaCanalPago = false;
            }
            
          }, ERR => {
            this.store.dispatch(DesactivarLoadingAction());
          });
        }
        
        // this.blockUIElement.stop();
        
      }, error => {
        this.store.dispatch(DesactivarLoadingAction());
        if(error.status == 400){
          // this.blockUIElement.stop();//
          if(error?.error[0]?.Key != 'Importe')this.store.dispatch(MostrarMensajeErrorAction({ titulo : 'Pago no disponible', mensaje : error.error[0].Exception}));
          if(error?.error[0]?.Key == 'Importe'){
            this.store.dispatch(MostrarMensajeErrorAction({ titulo : 'Pago sólo por Banco', mensaje : error?.error[0].Exception}));
            this.centroPagosService.execute({ hayCotizacion: true}, 'monto_excedido');
          }
          this.formPago.disable();
        }else{
          this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Error en el sistema', mensaje : error.error}));
        }
      });

    }

    // this.blockUIElement.stop();

  }

  ngOnDestroy(): void {
    this.subsCambiosForm.unsubscribe();
    this.subsAvisoPrivacidad.unsubscribe();
    this.subsTarjetaValor.unsubscribe();
  }

  cambioCanal(evento){
    if(evento.value != 0){
      this.cargaCanalPago = true;
      
      this.atributosDinamicos.forEach(atributo => {
        this.formPago.controls[atributo].disable();
      });

    }else{
      this.cargaCanalPago = false;

      this.atributosDinamicos.forEach(atributo => {
        this.formPago.controls[atributo].enable();
      });
    }

    this.formPago.get('IdCanalPago').setValue(evento.value);
  }

  aplicarPromocion(claveBanco: number) {
    const banco = this.bancosMeses.find(banco => banco.cve_banco == claveBanco);

    if (banco.seleccionado) {
      banco.seleccionado = false;
      this.opcionesPago = [
        {descripcion: 'PAGO EN UNA EXHIBICIÓN', valor: -1}
      ];
      if (this.descuento.length > 0) {
        this.opcionesPago = [
          {descripcion: 'PAGO EN UNA EXHIBICIÓN', valor: -1}
        ];
        this.existeDescuento = true;
        this.opcionesPago.push({descripcion: `DESCUENTO POR 80 ANIVERSARIO`, valor: this.descuento[0].cve_descuento});
      }

    } else {
      if (this.descuento && this.existeDescuento) {
        this.store.dispatch(ActivarLoadingAction());
        this.centroPagosService.actualizaDescuento(this.datosPago.idCarrito, this.cve_recibo, this.descuento, 3).subscribe((data:number) => {
          this.store.dispatch(DesactivarLoadingAction());
          let newImporte = data;
          if (newImporte != null) {
            this.importe = newImporte;
          }
          this.existeDescuento = false;
          this.selectDescuento = false;
        }, error => {
          this.store.dispatch(DesactivarLoadingAction());
          if(error.status == 400){
            // this.blockUIElement.stop();
            this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Pago no disponible', mensaje : error.error[0].Exception}));
            this.formPago.disable();
          }else{
            this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Error en el sistema', mensaje : error.error}));
          }
        });
      }
      this.bancosMeses.forEach(banco => banco.seleccionado = false);
      this.opcionesPago = [];
      banco.seleccionado = true;
      this.opcionesPago = [
        {descripcion: 'PAGO EN UNA EXHIBICIÓN', valor: -1}
      ];      
      banco.meses.forEach(mes => {
        this.opcionesPago.push({descripcion: `${mes} MESES SIN INTERESES`, valor: mes})
      });
    }

  }

  validaDescuento(evento) {
    if (evento.value === -1) {
      this.bancosMeses.forEach(banco => banco.seleccionado = false);
      this.opcionesPago = [
        {descripcion: 'PAGO EN UNA EXHIBICIÓN', valor: -1}
      ];
      if (this.descuento.length > 0) {
        this.opcionesPago = [
          {descripcion: 'PAGO EN UNA EXHIBICIÓN', valor: -1}
        ];
        this.existeDescuento = true;
        this.opcionesPago.push({descripcion: `DESCUENTO POR 80 ANIVERSARIO`, valor: this.descuento[0].cve_descuento});
      }
    }
    if (this.descuento && this.existeDescuento) {
      if (evento.value === 1) {
        this.store.dispatch(ActivarLoadingAction());
        this.centroPagosService.actualizaDescuento(this.datosPago.idCarrito, this.cve_recibo, this.descuento, 1).subscribe((data: number) => {
          this.store.dispatch(DesactivarLoadingAction());
          let newImporte = data;
          if (newImporte != null) {
            this.importe = newImporte;
          }
          this.selectDescuento = true;
        }, error => {
          this.store.dispatch(DesactivarLoadingAction());
          if(error.status == 400){
            // this.blockUIElement.stop();
            this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Pago no disponible', mensaje : error.error[0].Exception}));
            this.formPago.disable();
          }else{
            this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Error en el sistema', mensaje : error.error}));
          }
        });
      } else {
        this.store.dispatch(ActivarLoadingAction());
        this.centroPagosService.actualizaDescuento(this.datosPago.idCarrito, this.cve_recibo, this.descuento, 3).subscribe((data: number) => {
          this.store.dispatch(DesactivarLoadingAction());
          let newImporte = data;
          if (newImporte != null) {
            this.importe = newImporte;
          }
          this.selectDescuento = false;
        }, error => {
          this.store.dispatch(DesactivarLoadingAction());
          if(error.status == 400){
            // this.blockUIElement.stop();
            this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Pago no disponible', mensaje : error.error[0].Exception}));
            this.formPago.disable();
          }else{
            this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Error en el sistema', mensaje : error.error}));
          }
        });        
      }
    }
  }

  crearFormTarjeta(){
    this.formPago = new FormGroup({
      IdCarrito: new FormControl(null),
      IdCanalPago: new FormControl(null),
      GuardarTarjeta: new FormControl(false),
      AppOrigen: new FormControl(null),
    	NumeroTarjeta: new FormControl(null, [Validators.required, Validators.minLength(15)]),
    	NumeroTarjetaFront: new FormControl(null, [Validators.required, Validators.minLength(15)]),
    	CodigoSeguridad: new FormControl(null, [Validators.required, Validators.minLength(3)]),
    	MesVencimiento: new FormControl(null, [Validators.required]),
    	AnioVencimiento: new FormControl(null, [Validators.required]),
    	NombreTarjetahabiente: new FormControl(null, [Validators.required, Validators.minLength(8)]),
    	MesesSinIntereses: new FormControl(1, [Validators.required]),
    	Domiciliacion: new FormControl(false),
    	Email: new FormControl(null, [Validators.required, Validators.email]),
      CodigoDescuento: new FormControl(''),
    })
  }

  cargarMesesAnios(){
    for(let x = 1; x <= 12; x++){
      const mes = x.toString().length == 1 ? '0' + x : x.toString();
      this.meses.push(mes);
    }
    
    for(let x = 0; x <= 10; x++){
      const anio = this.fecha.clone().add(x, 'year');
      this.anios.push(anio.format('YY'));
    }
  }

  async realizarPago(){

    this.formPago.patchValue({
      AppOrigen: this.datosPago.appOrigen,
      IdCarrito: this.datosPago.idCarrito
    });
    const DOMI_SLIDER = this.formPago.get("Domiciliacion").value;
    if (this.articulos.length === 1 ? DOMI_SLIDER !== this.articulos[0].Domicilia : false){
    // if(this.formPago.get("Domiciliacion").value && this.articulos.length == 1){

      const edicion = {
        IdCarrito: this.datosPago.idCarrito,
        Articulos: [
          {
            Operacion: this.articulos[0].Operacion,
            CadenaIdentificacion: this.articulos[0].CadenaIdentificacion,
            Domicilia: DOMI_SLIDER,
            RenuevaDomiciliacion: DOMI_SLIDER,
            Moneda: this.articulos[0].Moneda,
            Estatus: 1
          }
        ]
      }

      try {
        const carrito = await this.centroPagosService.agregarEditarCarrito(edicion).toPromise();
      } catch(e) {

      }

      // this.centroPagosService.agregarEditarCarrito(edicion).subscribe(data => {
      // });
    }
    this.store.dispatch(ActivarLoadingAction());
    this.centroPagosService.realizarPago(this.formPago.value).subscribe(data => {
      this.store.dispatch(DesactivarLoadingAction());
      // this.blockUIElement.stop();
      if (this.datosPago.asegurado && this.formPago.get('GuardarTarjeta').value) {
        const fecha = moment(`01/${this.formPago.get('MesVencimiento').value}/${this.formPago.get('AnioVencimiento').value}`, 'DD/MM/YY');
        const form = {
          Numero: this.formPago.get('NumeroTarjeta').value,
          Vencimiento: fecha.format('YYYY/MM/DD'),
          CCV: this.formPago.get('CodigoSeguridad').value,
          NombreTarjetahabiente: this.formPago.get('NombreTarjetahabiente').value,
        }
        this.guardarTarjeta(form);
      }
      return Swal.fire('¡¡Gracias!!', `El pago se ha realizado exitosamente`, 'success').then(() => {
        this.resultadoPago.emit(data);
      })
      
    },error => {
      this.store.dispatch(DesactivarLoadingAction());
      if(error.status == 400){
        this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Error al realizar el pago', mensaje : error.error[0].Exception}));
      }else{
        this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Error en el sistema', mensaje : error.error ? error.error.Message : 'Error'}));
      }
      // this.blockUIElement.stop();
      this.resultadoPago.emit(null);
      let errorMaxi = {tipo: 500,tipoErro: error}
      this.resultadoPago.emit(errorMaxi); 
    });

  }

  guardarTarjeta(form) {
    // this.blockUIElement.start();
    
    this.store.dispatch(ActivarLoadingAction());
    this.centroPagosService.agregarTarjeta(form).pipe(retry(1)).subscribe(resp => {
      this.store.dispatch(DesactivarLoadingAction());
      // this.blockUIElement.stop();
    }, err => {
      this.store.dispatch(DesactivarLoadingAction());
      // this.blockUIElement.stop();
      this.store.dispatch(MostrarMensajeErrorAction({titulo : 'Notificación', mensaje : 'No se logró agregar la tarjeta de crédito'}));
    });
  }

  // // Para mostrar errores de validaciones en formulario (mat-error)
  hasError(controlName: string, errorName: string, formulario?: string){
    return this.formPago.controls[controlName].hasError(errorName);
  }

  validarJSONstring(str): any {
    if (!str) return { };
    try {
        JSON.parse(str);
    } catch (e) {
        return JSON.parse(str.replace("[","").replace("},", "}" ));
    }
    return JSON.parse(str);;
  }

  get CVVMaxLng () : number {
    const TC = this.formPago.get('NumeroTarjetaFront').value;
    if (!TC) return 3;
    return TC.length === 15 ? 4 : 3; 
  }

  get TCBrandClassIcon () : string {
    const TC = this.formPago.get('NumeroTarjetaFront').value;
    if (TC ? TC.length < 15 : true ) return 'tc-icono far fa-credit-card';
    if (TC.length === 15) return 'tc-icono fab fa-cc-amex';
    return TC.charAt(0) == 4 ? 'tc-icono fab fa-cc-visa' : 'tc-icono fab fa-cc-mastercard';
  }

}